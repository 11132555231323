/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
// import { createApp } from 'vue';
import 'alpinejs';
import 'bootstrap';

import AOS from 'aos';

// import ExampleComponent from './components/NavbarComponent.vue';

// createApp({
//     components: {
//         ExampleComponent
//     }
// }).mount("#app");
require("./bootstrap");

// ..

AOS.init();
